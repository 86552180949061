export const AboutUs = () => {
    window.scrollTo(0, 0);

    return (<>
        <div className="about">
            <section className="mb-md-5 mb-3 " id="how_it_works">
                <div className="text-white container">
                    <div className="d-flex align-items-center row">
                        <div className="pe-5 mb-3   col-md-12">
                            <p
                                className="text-uppercase fs-18 fs-fm-16 fw-500 text-main-green text-center pb-1 mb-1 mt-5 aos-init"
                                data-aos="fade-up"
                            >
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth={0}
                                    viewBox="0 0 16 16"
                                    className="me-1"
                                    height={8}
                                    width={8}
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx={8} cy={8} r={8} />
                                </svg>
                                PROVIDING REGULAR INCOME WITH 150% RETURN ON MATUARITY
                            </p>
                            <p
                                className="fw-bold fs-48 text-center content-text pb-3 mt-0 aos-init"
                                data-aos="fade-up"
                            >
                                Concept of Virtual Property?
                            </p>
                            <p
                                className="text-center d-flex align-items-center justify-content-center ms-1 aos-init"
                                data-aos="fade-up"
                            >
                                Virtual Property is a comparative concept promoted by us for helping one plan their investments and cash flow prudently. The last decades have witness fraudulent activities of builder in India where either they have not able to fulfill their promise or have done a third class construction which has eventually devalued over time instead of growing. We come across so many people among our society who got into trap of some builder and lost all his hard earned money as well as dreams and stability of their and their family lives. Virtual Property is way to explain how you can have a better passive income over a general rental income which may or may not come from buying a property. We have discussed pros and cons of different aspects in our presentation.
                            </p>
                            <p
                                className="fw-bold fs-48 text-center content-text pb-3 mt-0 aos-init"
                                data-aos="fade-up"
                            >
                                Who are the Guarantor of Money?
                            </p>
                            <p
                                className="text-center d-flex align-items-center justify-content-center ms-1 aos-init"
                                data-aos="fade-up"
                            >
                                The national level houses like Birla, TATA, HDFC, ICICI are the one who guarantees your income and principal. So you don’t need to worry about a single penny. All the cash flows are fully secured. As soon as you pay your first booking amount, these nation level houses has to back it by having 15 times the booking amount as guaranteed towards that virtual property. For example, let say one books a Virtual Property of 1 Crore by paying booking amount of 10% of 1 Cores i.e. 10 Lacs. Now in order to accept this Rs. 10 Lacs, these companies has to have a asset of Rs. 150 Lacs. This is how safe and secure the cash flow and capital.                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-md-3 mb-3">
                <div className="text-white container">
                    <section>
                        <div className="container">
                            <div className="d-flex justify-content-center align-items-center row">
                                <div className="text-center mb-4 col">
                                    <p
                                        className="text-uppercase fs-18 fs-fm-16 fw-500 text-main-green pb-1 mb-1 aos-init"
                                        data-aos="fade-up"
                                    >
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth={0}
                                            viewBox="0 0 16 16"
                                            className="me-1"
                                            height={8}
                                            width={8}
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx={8} cy={8} r={8} />
                                        </svg>
                                        INVEST WITH US, CREATE 2nd INCOME STREAM
                                    </p>
                                    <p
                                        className="text-white fw-600 display-6 mb-3 mb-md-4 content-text aos-init"
                                        data-aos="fade-up"
                                    >
                                        Why choose Virtual Property?
                                    </p>
                                </div>
                            </div>
                            <div className="text-white row">
                                <div
                                    data-aos="fade-up"
                                    className="d-flex justify-content-center ps-md-5 ps-lg-3 col-lg-4 col-md-12 col-12 aos-init"
                                >
                                    <div
                                        className=" text-center darkcard rounded p-1 d-flex mb-3 card"
                                        style={{ width: "28rem" }}
                                    >
                                        <div className=" bg-dark-gradient rounded card-body">
                                            <img
                                                src="https://cms.growpital.com/uploads/strong_69773a9079.svg"
                                                alt=""
                                                className="img-fluid my-3"
                                            />
                                            <div className="mt-3">
                                                <div className="fw-600 fs-22 text-white text-uppercase card-title h5">
                                                    STRONG EXECUTION
                                                </div>
                                                <p className="fw-300 fs-14 text-white  card-text">
                                                    Tech driven and process orientated investment projects with no
                                                    default till date.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    data-aos="fade-up"
                                    className="d-flex justify-content-center ps-md-5 ps-lg-3 col-lg-4 col-md-12 col-12 aos-init"
                                >
                                    <div
                                        className=" text-center darkcard rounded p-1 d-flex mb-3 card"
                                        style={{ width: "28rem" }}
                                    >
                                        <div className=" bg-dark-gradient rounded card-body">
                                            <img
                                                src="https://cms.growpital.com/uploads/graphs_economy_svgrepo_com_8f90b00dfe.svg"
                                                alt=""
                                                className="img-fluid my-3"
                                            />
                                            <div className="mt-3">
                                                <div className="fw-600 fs-22 text-white text-uppercase card-title h5">
                                                    ECONOMIES OF SCALE
                                                </div>
                                                <p className="fw-300 fs-14 text-white  card-text">
                                                    9+ Crore of portfolio
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    data-aos="fade-up"
                                    className="d-flex justify-content-center ps-md-5 ps-lg-3 col-lg-4 col-md-12 col-12 aos-init"
                                >
                                    <div
                                        className=" text-center darkcard rounded p-1 d-flex mb-3 card"
                                        style={{ width: "28rem" }}
                                    >
                                        <div className=" bg-dark-gradient rounded card-body">
                                            <img
                                                src="https://cms.growpital.com/uploads/team_svgrepo_com_ee28173bb1.svg"
                                                alt=""
                                                className="img-fluid my-3"
                                            />
                                            <div className="mt-3">
                                                <div className="fw-600 fs-22 text-white text-uppercase card-title h5">
                                                    STRONG TEAM
                                                </div>
                                                <p className="fw-300 fs-14 text-white  card-text">
                                                    Cumulative experience in Investment, management, finance,
                                                    marketing and operations.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            {/* <section className="pt-5 mb-md-4 mb-3" id="team">
                <div className="container">
                    <div className="superstarsAbout">
                        <div className="row">
                            <div className="text-center  col">
                                <p
                                    className="text-uppercase fs-18 fw-500 fs-fm-16 text-main-green pb-1 mb-1 aos-init"
                                    data-aos="fade-up"
                                >
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 16 16"
                                        className="me-1"
                                        height={8}
                                        width={8}
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle cx={8} cy={8} r={8} />
                                    </svg>
                                    WE ARE NOT JUST A TEAM, WE ARE A FAMILY
                                </p>
                                <h2
                                    className="text-white fw-600 display-6 mb-0 mb-md-4 content-text aos-init"
                                    data-aos="fade-up"
                                >
                                    The spirit behind{" "}
                                    <span className="text-main-green"> Virtual Property</span>
                                </h2>
                                <div className="d-flex justify-content-center align-items-start py-5 pt-md-0  row">
                                    <div
                                        data-aos="fade-up"
                                        className="d-none d-lg-block col-md-6 aos-init"
                                    >
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-3 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src=""
                                                            alt="profile pic"
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Ashish Agrawal
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director</cite>
                                                            <a
                                                                href="https://www.linkedin.com/in/riturajsharma123/"
                                                                target="blank"
                                                            >
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Intro
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        data-aos="fade-up"
                                        className="d-none d-lg-block col-md-6 aos-init"
                                    >
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-3 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src=""
                                                            alt="profile pic"
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Name
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director-Farms</cite>
                                                            <a
                                                                href="https://www.linkedin.com/in/krishnna-joshi-a33638118/"
                                                                target="blank"
                                                            >
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Intro
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events d-lg-none">
                            <div
                                className="swiper-wrapper"
                                style={{ cursor: "grab", transitionDuration: "0ms" }}
                            >
                                <div
                                    className="swiper-slide swiper-slide-duplicate"
                                    data-swiper-slide-index={6}
                                >
                                    <div className="col-lg-6 col-md-12">
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-4 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src="https://cms.growpital.com/uploads/Shubhanshu_Chouhan_d023e38f17.jpg"
                                                            alt=""
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Shubhanshu Chouhan
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director-Technology</cite>
                                                            <a target="blank">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Passionate about developing high-performing teams
                                                            using transformational leadership to accelerate
                                                            business capabilities and optimize IT value.
                                                            Recognized for creating impactful relationships,
                                                            strategic sourcing, consensus building, and leading
                                                            full-cycle global projects.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="swiper-slide swiper-slide-duplicate"
                                    data-swiper-slide-index={7}
                                >
                                    <div className="col-lg-6 col-md-12">
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-4 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src="https://cms.growpital.com/uploads/Fahad_Shaikh_c6c7405150.jpg"
                                                            alt=""
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Fahad Shaikh
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director- Farm Operations</cite>
                                                            <a target="blank">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Fahad's experience spans more than a decade in Supply
                                                            Chain, Agriculture, Restaurants, Entrepreneurship and
                                                            Telecommunication sector. He has operational skills
                                                            and also possesses expertise in team management,
                                                            vendor management, client management, and quality
                                                            assurance.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide" data-swiper-slide-index={0}>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-4 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src="https://cms.growpital.com/uploads/Rituraj_Sharma_8bea54ef16.jpg"
                                                            alt=""
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Rituraj Sharma
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director</cite>
                                                            <a target="blank">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Rituraj is an alumnus of IMT Nagpur and an engineer.
                                                            He has over 10 years of experience being a serial
                                                            entrepreneur in industries related to Solar Energy
                                                            &amp; Agriculture.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide" data-swiper-slide-index={1}>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-4 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src="https://cms.growpital.com/uploads/Krishnna_Joshi_394b072481.jpg"
                                                            alt=""
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Krishna Joshi
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director-Farms</cite>
                                                            <a target="blank">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Krishna comes with vast experience of 12 years across
                                                            industries ranging from BFSI, Marketing &amp;
                                                            automobile. His educational background in commerce
                                                            &amp; business management have led him to see through
                                                            various industries.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide" data-swiper-slide-index={2}>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-4 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src="https://cms.growpital.com/uploads/Sudhir_Paswan_79054509bb.jpg"
                                                            alt=""
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Sudhir Paswan
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director- Agronomy</cite>
                                                            <a target="blank">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            An Agriculture Graduate with 16+ years of agribusiness
                                                            experience with Corporates and social organisations
                                                            like Reliance, REI, PepsiCo, KGVK, Swades Foundation.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide" data-swiper-slide-index={3}>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-4 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src="https://cms.growpital.com/uploads/Karan_Agrawal_c112da518d.jpg"
                                                            alt=""
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Karan Agrawal
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director- Business Finance</cite>
                                                            <a target="blank">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Karan is a self driven person with diverse experience
                                                            of around 10 years in power and agriculture sector. He
                                                            is B. Tech (Honors) in Mechanical engineering from NIT
                                                            Raipur &amp; PGDM Operations and Strategy from MDI
                                                            Gurgaon.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide" data-swiper-slide-index={4}>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-4 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src="https://cms.growpital.com/uploads/Ankush_Ramesh_Agrawal_dcb49ae283.jpg"
                                                            alt=""
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Ankush Agrawal
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director- Business Intelligence</cite>
                                                            <a target="blank">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Ankush has 10+ years experience spanning Social Media
                                                            &amp; Digital Marketing, Market Research &amp;
                                                            Consumer Insights, Data Analytics &amp; Business
                                                            Strategy responsibilities across multiple sectors. A
                                                            PGDM in Marketing &amp; Operations from IMT Nagpur
                                                            &amp; B Tech UDCT Mumbai graduate.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide" data-swiper-slide-index={5}>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-4 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src="https://cms.growpital.com/uploads/Utkarsh_Srivastava_d09fbc3235.jpg"
                                                            alt=""
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Utkarsh Srivastava
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director- Business</cite>
                                                            <a target="blank">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Utkarsh is a first principles person. Graduated from
                                                            IMT Nagpur &amp; did his B Tech in computer science
                                                            prior to that. He is also an Alumnus of IIM Lucknow.
                                                            He has over a decade of diversified experience in
                                                            BFSI, Fintech and Edtech industries.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide" data-swiper-slide-index={6}>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-4 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src="https://cms.growpital.com/uploads/Shubhanshu_Chouhan_d023e38f17.jpg"
                                                            alt=""
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Shubhanshu Chouhan
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director-Technology</cite>
                                                            <a target="blank">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Passionate about developing high-performing teams
                                                            using transformational leadership to accelerate
                                                            business capabilities and optimize IT value.
                                                            Recognized for creating impactful relationships,
                                                            strategic sourcing, consensus building, and leading
                                                            full-cycle global projects.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide" data-swiper-slide-index={7}>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-4 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src="https://cms.growpital.com/uploads/Fahad_Shaikh_c6c7405150.jpg"
                                                            alt=""
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Fahad Shaikh
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director- Farm Operations</cite>
                                                            <a target="blank">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Fahad's experience spans more than a decade in Supply
                                                            Chain, Agriculture, Restaurants, Entrepreneurship and
                                                            Telecommunication sector. He has operational skills
                                                            and also possesses expertise in team management,
                                                            vendor management, client management, and quality
                                                            assurance.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                    data-swiper-slide-index={0}
                                >
                                    <div className="col-lg-6 col-md-12">
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-4 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src="https://cms.growpital.com/uploads/Rituraj_Sharma_8bea54ef16.jpg"
                                                            alt=""
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Rituraj Sharma
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director</cite>
                                                            <a target="blank">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Rituraj is an alumnus of IMT Nagpur and an engineer.
                                                            He has over 10 years of experience being a serial
                                                            entrepreneur in industries related to Solar Energy
                                                            &amp; Agriculture.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="swiper-slide swiper-slide-duplicate"
                                    data-swiper-slide-index={1}
                                >
                                    <div className="col-lg-6 col-md-12">
                                        <div className="darkcard mb-4 mb-md-4 p-0 rounded text-start text-white card">
                                            <div className="m-1 bg-dark-gradient rounded p-4 card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <img
                                                            src="https://cms.growpital.com/uploads/Krishnna_Joshi_394b072481.jpg"
                                                            alt=""
                                                            className="img-fluid w-100 mb-md-0 mb-3"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="fw-600 fs-20 mb-0 pt-2 card-title h5">
                                                            Krishna Joshi
                                                        </div>
                                                        <div className="fw-600 fs-14 mb-3 pt-2 text-italian card-title h5">
                                                            <cite>Director-Farms</cite>
                                                            <a target="blank">
                                                                <svg
                                                                    stroke="currentColor"
                                                                    fill="currentColor"
                                                                    strokeWidth={0}
                                                                    viewBox="0 0 1024 1024"
                                                                    className="ms-2 text-indigo"
                                                                    height={18}
                                                                    width={18}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <p className="fw-300 fs-15 lh-24 card-text">
                                                            Krishna comes with vast experience of 12 years across
                                                            industries ranging from BFSI, Marketing &amp;
                                                            automobile. His educational background in commerce
                                                            &amp; business management have led him to see through
                                                            various industries.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className=" mb-md-5 mb-3">
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center row">
                        <div className="text-center col">
                            <p
                                className="text-uppercase fs-18 fw-500 fs-fm-16 text-main-green pb-1 mb-1 aos-init"
                                data-aos="fade-up"
                            >
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth={0}
                                    viewBox="0 0 16 16"
                                    className="me-1"
                                    height={8}
                                    width={8}
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx={8} cy={8} r={8} />
                                </svg>
                                WANT TO KNOW HOW IT WORKS?
                            </p>
                            <h2
                                className="text-white fw-600 display-6 mb-0 mb-md-4 content-text aos-init"
                                data-aos="fade-up"
                            >
                                Know how your capital helps you make money
                            </h2>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-start pt-3 pt-md-0 row">
                        <div data-aos="fade-up" className="col aos-init">
                            <img
                                src="https://cms.growpital.com/uploads/about_section_32879d295f.jpg"
                                alt=""
                                className="img-fluid w-100"
                            />
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className="bg-charade py-5 mb-md-5 mb-3">
                <div className="py-0 py-md-0 container">
                    <div className="d-flex justify-content-center align-items-center row">
                        <div data-aos="fade-up" className="text-center col aos-init">
                            <p className="text-uppercase fs-18 fs-fm-16 fw-500 text-main-green pb-0 mb-0">
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth={0}
                                    viewBox="0 0 16 16"
                                    className="me-1"
                                    height={8}
                                    width={8}
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx={8} cy={8} r={8} />
                                </svg>
                                WE WAITED UNTIL WE COULD DO IT RIGHT
                            </p>
                            <p className="text-white fw-600 fs-48 mb-3 mb-md-4 content-text">
                                Meet our Portfolio Partners
                            </p>
                        </div>
                    </div>
                    <div data-aos="fade-up" className="row aos-init">
                        <div className="about-logo pb-4 ">
                            <div
                                className="slick-slider bg-white p-4  about-slider slick-initialized"
                                dir="ltr"
                            >
                                <div className="slick-list" style={{ padding: "0px 100px" }}>
                                    <div
                                        className="slick-track"
                                        style={{
                                            width: 4640,
                                            opacity: 1,
                                            transform: "translate3d(-1160px, 0px, 0px)"
                                        }}
                                    >
                                        <div
                                            data-index={-4}
                                            tabIndex={-1}
                                            className="slick-slide slick-cloned"
                                            aria-hidden="true"
                                            style={{ width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={-3}
                                            tabIndex={-1}
                                            className="slick-slide slick-cloned"
                                            aria-hidden="true"
                                            style={{ width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src=""
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={-2}
                                            tabIndex={-1}
                                            className="slick-slide slick-cloned"
                                            aria-hidden="true"
                                            style={{ width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src=""
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={-1}
                                            tabIndex={-1}
                                            className="slick-slide slick-cloned"
                                            aria-hidden="true"
                                            style={{ width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={0}
                                            className="slick-slide slick-active"
                                            tabIndex={-1}
                                            aria-hidden="false"
                                            style={{ outline: "none", width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={1}
                                            className="slick-slide slick-active slick-center slick-current"
                                            tabIndex={-1}
                                            aria-hidden="false"
                                            style={{ outline: "none", width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={2}
                                            className="slick-slide slick-active"
                                            tabIndex={-1}
                                            aria-hidden="false"
                                            style={{ outline: "none", width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={3}
                                            className="slick-slide"
                                            tabIndex={-1}
                                            aria-hidden="true"
                                            style={{ outline: "none", width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={4}
                                            className="slick-slide"
                                            tabIndex={-1}
                                            aria-hidden="true"
                                            style={{ outline: "none", width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={5}
                                            className="slick-slide"
                                            tabIndex={-1}
                                            aria-hidden="true"
                                            style={{ outline: "none", width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={6}
                                            tabIndex={-1}
                                            className="slick-slide slick-cloned"
                                            aria-hidden="true"
                                            style={{ width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={7}
                                            tabIndex={-1}
                                            className="slick-slide slick-center slick-cloned"
                                            aria-hidden="true"
                                            style={{ width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={8}
                                            tabIndex={-1}
                                            className="slick-slide slick-cloned"
                                            aria-hidden="true"
                                            style={{ width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={9}
                                            tabIndex={-1}
                                            className="slick-slide slick-cloned"
                                            aria-hidden="true"
                                            style={{ width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={10}
                                            tabIndex={-1}
                                            className="slick-slide slick-cloned"
                                            aria-hidden="true"
                                            style={{ width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-index={11}
                                            tabIndex={-1}
                                            className="slick-slide slick-cloned"
                                            aria-hidden="true"
                                            style={{ width: 290 }}
                                        >
                                            <div>
                                                <div
                                                    className="about-slider"
                                                    tabIndex={-1}
                                                    style={{ width: "100%", display: "inline-block" }}
                                                >
                                                    <img
                                                        src="../../../src/assets/images/AdityaBirlaGroup.jpg"
                                                        alt=""
                                                        className=" me-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    </>)
}